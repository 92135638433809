<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2019-11-19 16:44:33
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-02 09:54:32
 -->

<template>
  <div class="public_footer">
    <div class="common_width">
      <img src="@/assets/header_qrcode.jpg" />
      <div class="section">
        <p class="title">联系我们</p>
        <p class="inline even">咨询热线：400-880-1190</p>
        <p class="inline">
          咨询客服：
          <a href="javascript:void(0);" @click="easemobim.bind()">（点击咨询）</a>
        </p>
        <br />
        <p class="inline even">微信咨询：13006623709</p>
        <p class="inline">公司地址：深圳市南山区科技园南区数字技术园B1栋6A</p>
      </div>

      <!-- <div class="section">
        <p class="title">关于我们</p>
        <p>
          工蚁宝，互联网+灵活用工平台
          <br />致力于为企业提供灵活用工SAAS化解决方案，搭建企业与灵活用工雇佣关系的桥梁，
          <br />全方位帮助灵活用工企业处理财税结算与用工管理问题，大幅降低企业成本，提高企业效益。
          <br />工蚁宝，依托领先的大数据与云计算技术，采用高并发、高可用、高安全的微服务架构体系，通过灵工人员管理系统、合同信息网签系统、财税结算管理系统、企业银企直联系统、
          <br />用工智能分析系统五大核心系统，提供安全、可靠的SAAS化用工服务能力和整体解决方案，为企业的合规经营与高速发展保驾护航 。
        </p>
      </div>-->

      <div class="section">
        <p class="title">友情链接</p>
        <ul>
          <li v-for="(item,index) in friendLinks" :key="'friendLinks'+index">
            <a target="_blank" :href="item.url" :title="item.name">{{item.name}}</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="copyRight">
      <p>
        <span>©Copyright 2019. 工蚁宝版权所有</span>
        <span>|</span>
        <a href="http://www.beian.miit.gov.cn" target="_blank">粤ICP备19110558号-1</a>
      </p>
    </div>
  </div>
</template>

<script>
import api from "@/api/list";
export default {
  name: "grejobFooter",
  data() {
    return {
      friendLinks: [],
    };
  },
  mounted() {
    api.getFriendLinks().then((res) => {
      if (res.data) {
        this.friendLinks = res.data;
      }
    });

    this.easemobim = window.easemobim || {};
    this.easemobim.config = {
      hide: true,
      //autoConnect: true,
      configId: "aaab0cb4-4a17-478b-9ab7-e90e515fbb15",
      dialogPosition: { x: "100px", y: "10px" },
    };
  },
};
</script>