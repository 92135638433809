/*
 * @Descripttion:
 * @version:
 * @Author: gaolongzhi
 * @Date: 2020-08-14 09:43:12
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-09 13:36:53
 */
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import jwt_decode from "jwt-decode";
import { getToken, setToken } from "../utils/auth";

Vue.use(Router);

export const routes = [
  {
    path: "/login",
    component: () => import("../views/login/index.vue"),
    meta: {
      title: "账号密码登录",
      noAuth: true,
      header: false,
    },
  },
  {
    path: "/register",
    component: () => import("../views/login/register.vue"),
    meta: {
      title: "新用户注册",
      noAuth: true,
      header: true,
    },
  },
  {
    path: "/resetPassword",
    component: () => import("../views/login/resetPassword.vue"),
    meta: {
      title: "忘记密码",
      noAuth: true,
      header: true,
    },
  },
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    meta: {
      title: "首页",
      noAuth: true,
      header: true,
    },
  },
  {
    path: "/tasks",
    component: () => import("../views/tasks/index.vue"),
    meta: {
      title: "任务大厅",
      noAuth: true,
      header: true,
    },
  },
  {
    path: "/tasksDetail",
    component: () => import("../views/tasks/detail.vue"),
    meta: {
      title: "任务详情",
      noAuth: true,
      header: true,
    },
  },
  {
    path: "/myTasks",
    component: () => import("../views/tasks/myIndex.vue"),
    meta: {
      title: "我的任务",
      header: true,
    },
  },
  {
    path: "/myTasksDetails",
    component: () => import("../views/tasks/myDetail.vue"),
    meta: {
      title: "我的任务详情",
      header: true,
    },
  },
  {
    path: "/salary",
    component: () => import("../views/salary/index.vue"),
    meta: {
      title: "我的收入",
      header: true,
    },
    redirect: "/salary/income",
    children: [
      {
        path: "income",
        component: () => import("../views/salary/components/Income.vue"),
        meta: {
          title: "我的收入",
          header: true,
        },
      },
      {
        path: "record",
        component: () => import("../views/salary/components/Record.vue"),
        meta: {
          title: "资金记录",
          header: true,
        },
      },
    ],
  },
  {
    path: "/salary/withdrawals",
    component: () => import("../views/withdrawals/index.vue"),
    meta: {
      title: "提现",
      header: true,
    },
    name: "withdrawals",
  },
  {
    path: "/userCenter",
    component: () => import("../views/userCenter/layout.vue"),
    redirect: "/userCenter/index",
    children: [
      {
        path: "index",
        name: "个人中心",
        component: () => import("../views/userCenter/index.vue"),
        meta: {
          title: "个人中心",
          header: true,
        },
      },
      {
     //   path: "tasks",
          path: "tasksList",
        component: () => import("../views/userCenter/myTasks.vue"),
        meta: {
          title: "我的任务",
          header: true,
        },
      },
      {
        path: "tasksDetails",
        component: () => import("../views/userCenter/myTasksDetail.vue"),
        meta: {
          title: "任务详情",
          header: true,
        },
      },
      {
        path: "contracts",
        component: () => import("../views/userCenter/myContracts.vue"),
        meta: {
          title: "我的协议",
          header: true,
        },
      },
      {
        path: "signContract",
        component: () => import("../views/userCenter/signContract.vue"),
        meta: {
          title: "电签协议",
          header: true,
        },
      },
      {
        path: "signContractIndividual",
        component: () => import("../views/userCenter/signContractIndividual.vue"),
        meta: {
          title: "个体户电签协议",
          header: true,
        },
      },
      {
        path: "myIndividual",
        component: () => import("../views/userCenter/myIndividual.vue"),
        meta: {
          title: "我的个体户",
          header: true,
        },
      },
      {
        path: "individualResult",
        component: () => import("../views/userCenter/myIndividualResult.vue"),
        meta: {
          title: "个体户明细",
          header: true,
        },
      },
      {
        path: "messageList",
        component: () => import("../views/userCenter/messageList.vue"),
        meta: {
          title: "消息中心",
          header: true,
        },
      },

      {
        path: "messageDetail",
        name: "messageDetail",
        component: () => import("../views/userCenter/messageDetail.vue"),
        meta: {
          title: "消息详情",
          header: true,
        },
      },
      {
        path: "editPass",
        component: () => import("../views/userCenter/editPass.vue"),
        meta: {
          title: "修改密码",
          header: true,
        },
      },
      {
        path: "editTel",
        component: () => import("../views/userCenter/editTel.vue"),
        meta: {
          title: "修改手机号码",
          header: true,
        },
      },
      {
        path: "privacy",
        component: () => import("../views/userCenter/privacy.vue"),
        meta: {
          title: "隐私政策",
          header: true,
        },
      },
    ],
  },
  {
    path: "/pdfPreview",
    component: () => import("../components/pdfView/index.vue"),
    meta: {
      title: "文件预览",
    },
  },
  {
    path: "/help",
    component: () => import("../views/userCenter/help.vue"),
    meta: {
      title: "电签帮助",
    },
  },
];

const router = new Router({
  //  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //进入路由时将进入标志设置为true,并将请求数重置,使loading效果只在进入页面时展示
  store.commit("RESET_REQUEST_COUNTER");
  store.commit("SET_ENTER_ROUTE_LOADING", true);

  let token = to.query.token;
  if (token) {
    setToken(token);
    store.commit("SET_TOKEN", token);
    store.commit("SET_JWTDECODE", jwt_decode(token));
  }
  if (
    typeof getToken() != "undefined" &&
    getToken() != "undefined" &&
    getToken()
  ) {
    const timeLeft =
      store.getters.jwtDecode.exp - Math.round(new Date() / 1000);
    if (timeLeft <= 0) {
      if (to.meta.noAuth) {
        next();
      } else {
        store.commit("SET_ENTERROUTE", to); //记录进入路由 用于在登录后的重定向
        next({ path: "/login" });
      }
    } else {
      if (JSON.stringify(store.getters.user) === "{}") {
        store.dispatch("getUserInfo");
      }
      if (to.path === "/login") {
        next({ path: "/" });
      } else {
        next();
      }
    }
  } else {
    if (to.meta.noAuth) {
      next();
    } else {
      store.commit("SET_ENTERROUTE", to); //记录进入路由 用于在登录后的重定向
      next({ path: "/login" });
    }
  }
});

router.afterEach((to) => {
  window.scrollTo(0, 0); //页面返回顶部
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
});

export default router;
