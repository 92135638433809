/*
 * @Descripttion:
 * @version:
 * @Author: gaolongzhi
 * @Date: 2020-05-21 17:22:46
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-08-29 17:21:49
 */
const getters = {
  user: (state) => state.user.user,
  token: (state) => state.user.token,
  jwtDecode: (state) => state.user.jwtDecode,
  enterRoute: (state) => state.user.enterRoute,
  requestCounter: (state) => state.user.requestCounter,
  enterRouteLoading: (state) => state.user.enterRouteLoading,
  withdrawalsActive: (state) => state.withdrawals.active,
  withdrawalsToSecond: (state) => state.withdrawals.toSecond,
  withdrawalsToResult: (state) => state.withdrawals.toResult,
};
export default getters;
