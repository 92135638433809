<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-14 09:43:12
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-04 10:14:04
-->
<template>
  <div id="app">
    <grejob-header v-if="hidden" />
    <router-view />

    <job-loading />
    <grejob-footer v-if="hidden" />
  </div>
</template>

<script>
import grejobHeader from "@/components/header/index.vue";
import grejobFooter from "@/components/footer/index.vue";
import jobLoading from "@/components/loading/index.vue";
export default {
  components: {
    grejobHeader,
    grejobFooter,
    jobLoading,
  },
  data() {
    return {
      hidden: this.$route.meta.header,
    };
  },
  watch: {
    $route: function (to, from) {
      this.hidden = to.meta.header;
    },
  },
};
</script>
