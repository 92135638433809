const withdrawals = {
  namespaced: true,
  state: {
    active: 0,
    toSecond:[],
    toResult:[],
  },

  mutations: {
    SETACTIVE: (state, payload) => {
      state.active = payload;
    },
    SETTOSECOND: (state, payload) => {
      state.toSecond = payload;
    },
    SETTORESULT: (state, payload) => {
      state.toResult = payload;
    },
  },

  actions: {},
};

export default withdrawals;
