import {
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth';
import api from '@/api/list';
import jwt_decode from 'jwt-decode';

const user = {
  state: {
    user: {},
    token: getToken(),
    jwtDecode: getToken() && typeof getToken() != 'undefined' && getToken() != 'undefined' ? jwt_decode(getToken()) : {},
    enterRoute: "/",//进入的路由，用来做登录之后的重定向
    requestCounter: 0,//加载效果
    enterRouteLoading: false,
  },

  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
    SET_ENTERROUTE: (state, enterRoute) => {
      state.enterRoute = enterRoute;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_JWTDECODE: (state, jwtDecode) => {
      state.jwtDecode = jwtDecode;
    },
    SET_ENTER_ROUTE_LOADING: (state, enterRouteLoading) => {
      state.enterRouteLoading = enterRouteLoading;
    },
    RESET_REQUEST_COUNTER: (state) => {
      state.requestCounter = 0;
    },
    ADD_REQUEST_COUNTER: (state) => {
      state.requestCounter++;
    },
    DELETE_REQUEST_COUNTER: (state) => {
      if (state.requestCounter > 0) {
        state.requestCounter--;
      }
      //进入页面的请求数结束则将标志置为false
      if (state.requestCounter == 0) {
        state.enterRouteLoading = false;
      }
    },
  },

  actions: {
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if (getToken()) {
          api.getUserInfo().then(response => {
            let user = response.data;
            if (user.headshot) {//处理头像
              user.headshot = process.env.VUE_APP_FILE_PATH + user.headshot
            }

            if (user.skillCertificateFiles) {//处理技能证书文件
              let temp = user.skillCertificateFiles.split(",")
              temp.forEach((item, index) => {
                temp[index] = process.env.VUE_APP_FILE_PATH + item;
              });
              user.skillCertificateFiles = temp
            }
            commit('SET_USER', user);
            resolve(user);
          }).catch(error => {
            reject(error);
          });
        }

      })
    },
    // 账号密码登录
    Login({ commit }, userInfo) {
      commit('SET_TOKEN', '');
      commit('SET_JWTDECODE', {});
      removeToken();
      return new Promise((resolve, reject) => {
        api.login(userInfo, { method: 'post' })
          .then(response => {
            if (response.status == '200') {
              setToken(response.data.token);
              commit('SET_TOKEN', response.data.token);
              commit('SET_JWTDECODE', jwt_decode(response.data.token));
              resolve(response);
            } else {
              resolve(response);
            }
          }).catch(error => {
            reject(error);
          });
      });
    },

    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        api.logout().then(() => {
          commit('SET_TOKEN', '');
          commit('SET_JWTDECODE', {});
          removeToken();
          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },
  }
};

export default user;
