/*
 * @Descripttion:
 * @version:
 * @Author: gaolongzhi
 * @Date: 2020-05-06 15:15:39
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-08-28 17:40:10
 */
import Vue from "vue";
import Vuex from "vuex";
import user from "@/store/modules/user";
import withdrawals from "@/store/modules/withdrawals";
import getters from "./getters";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user: user,
    withdrawals: withdrawals,
  },
  getters,
});

export default store;
