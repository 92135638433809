import axios from 'axios';
import store from '../store';
import {
  getToken,
  setToken,
  removeToken
} from '@/utils/auth';

import { Message } from 'element-ui';
import api from './list';
import jwt_decode from 'jwt-decode';
import router from '../router/index.js';
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 50000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.enterRouteLoading) {
    store.commit('ADD_REQUEST_COUNTER')
  }

  // Do something before request is sent
  if (store.getters.token) { // 用户端
    config.headers['Authorization'] = getToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
  }

  return config;
}, error => {
  // Do something with request error
  Promise.reject(error);
})

service.interceptors.response.use(response => {
  store.commit('DELETE_REQUEST_COUNTER')
  if (response.status == 200) {
    if (response.data.status === 40101) {
      Message({
        showClose: true,
        message: '你已被登出,请重新登录！',
        type: 'warning'
      });
      removeToken()
      router.push({ path: "/login" });
      return Promise.reject(response.config.url + ":" + response.data.message);
    }

    if (response.data.status === 40301 || response.data.status === 40002) {
      if (response.config.url.indexOf('/api/contract/taskController/addTaskApply') >= 0) {
        removeToken()
        router.push({ path: "/login" });
        return Promise.reject("登录后才能申请任务")
      } else {
        Message({
          showClose: true,
          message: '当前用户无相关操作权限！',
          type: 'warning',
          duration: 5 * 1000
        });
        removeToken()
        router.push({ path: "/login" });
        return Promise.reject(response.config.url + ":" + response.data.message);
      }

    }

    if (response.data.status === 40001) {
      Message({
        showClose: true,
        message: response.data.message,
        type: 'warning',
        duration: 5 * 1000
      });
      return Promise.reject(response.config.url + ":" + response.data.message);
    }

    if (response.data.status === 40333) {
      Message({
        showClose: true,
        message: '非销售和代理商类型账号不能登录合作方平台！',
        type: 'warning',
        duration: 5 * 1000
      });
      return Promise.reject(response.config.url + ":" + response.data.message);
    }

    if (response.data.status === 40003) {
      store.dispatch('FedLogOut').then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      })
      return Promise.reject(response.config.url + ":" + response.data.message);
    }

    const timeLeft = (store.getters.jwtDecode.exp - Math.round(new Date() / 1000));//秒级
    if (typeof store.getters.token != 'undefined') {
      if (timeLeft > 60 && timeLeft <= 600) {
        api.refreshToken({ token: store.getters.token }).then(function (response) {
          setToken(response.data.data)
          store.commit('SET_TOKEN', response.data.data);
          store.commit('SET_JWTDECODE', jwt_decode(response.data.data));
        })
      } else if (timeLeft <= 60) {
        removeToken()
      }
    }


    if (response.data.status == 200 || response.data.status == 10001) {
      return response.data;
    } else {
      if (response.data.message) {
        Message({
          showClose: true,
          message: response.data.message,
          type: 'warning',
          duration: 5 * 1000
        });
      }
      return Promise.reject(response.config.url + ":" + response.data.message);
    }
  } else {
    if (response.data.message) {
      Message({
        showClose: true,
        message: response.data.message,
        type: 'warning',
        duration: 5 * 1000
      });
    }
    return Promise.reject(response.config.url + ":" + response.data.message);
  }
},
  error => {
    store.commit('DELETE_REQUEST_COUNTER')
    if (!error.response) {
      Message({
        showClose: true,
        message: "系统繁忙，请稍后再试！",
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(error);
    }
    const status = error.response.status
    //抛出自定义异常直接返回
    if (status === 500 && error.response.data.status > 1000) {
      return error.response
    }
    let ErrorMessage = ""
    if (status == 500) {
      ErrorMessage = "系统繁忙，请稍后再试！"
    } else if (status == 503) {
      ErrorMessage = "服务器暂无响应，请稍后再试！"
    } else {
      ErrorMessage = error.message
    }
    Message({
      showClose: true,
      message: ErrorMessage,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(error);
  });

export default service;

