/*
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-04-23 10:19:20
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-21 17:10:11
 */
import fetch from './index';
import { Message } from 'element-ui';

let apiList = {
  login: '/api/auth/jwt/employee/token',
  refreshToken: '/api/auth/jwt/refresh',
  forgetPassword: '/api/auth/jwt/employee/forgetPassword/',//忘记密码第一步
  setForgetPassword: '/api/companystaff/employeeAccount/forgetPassword',//忘记密码第二步
  getInfo: '/api/admin/user/front/info',
  getTokenByWechatCode: '/api/auth/jwt/employee/getTokenByWechatCode',
  mobileValidate: '/api/companystaff/employeeAccount/mobileValidate/',//修改手机号码 验证手机号
  updateMobile: '/api/companystaff/employeeAccount/updateMobile',//修改手机号码 提交
  getTokenByMiniOpenid: '/api/auth/jwt/employee/getTokenByMiniOpenid/',


  getDataDictList: '/api/admin/dataDictionary/list',


  userEditPass: '/api/admin/user/partnerEditPass',
  partnetGetActiveUserPartInfo: '/api/admin/user/getActiveUserPartInfo',
  partnetSetHeadshot: '/api/admin/user/setHeadshot',
  list: '/api/admin/area/listArea',


  contentFeedbackPostAction: '/api/content/contentFeedback/saveForUser',//对工资有疑问
  contentModulelistToActiveUser: '/api/content/contentModule/listToActiveUser',
  contentArticlelistToActiveUser: '/api/content/contentArticle/listToActiveUser',
  contentPosterlistToActiveUser: '/api/content/contentPoster/listToActiveUser',





  contractUrl: '/api/contract/contractEmployee/checkContract',//保存员工签署协议绑定银行卡

  createContract: '/api/contract/contractEmployee/createContract',//向e签宝发起协议申请
  individualFace: '/api/companystaff/employeeAccount/indivAuthUrl',//发起刷脸认证
  findIndividualFaceRes: '/api/companystaff/employeeAccount/findIndivAuthUrlRes',//查询刷脸认证结果


  employeeSetOidByCode: '/api/companystaff/employeeAccount/setOidByCode',
  employeePasswordValidate: '/api/companystaff/employeeAccount/passwordValidate',


  //pc端
  getImgCode: '/api/admin/vcc/code',
  sendSmsCode: '/api/admin/vcc/employeeLoginSms',
  sendSms: '/api/admin/vcc/sendSms', //修改手机号短信验证码
  validateCode: '/api/admin/vcc/validateCode',//校验图形验证码
  validateMessageCode: '/api/admin/vcc/validate',//校验手机短信验证码
  validatePromotionCode:'/api/companystaff/ecompanyJobCategory/getValidateCode',//校验邀请码
  uploadUrl: '/api/admin/file/upload/',
  setHeadshot: '/api/companystaff/employeeAccount/setHeadshot',
  tasksCenterList: '/api/contract/taskController/pageByUserMobile',
  tasksCenterListNew: '/api/contract/taskController/pageByUserMobileNew',
  taskCenterType: '/api/companystaff/ecompanyJobCategory/selectByAccount',
  getAllCity: '/api/admin/area/getAllCity',
  getUserInfo: '/api/companystaff/employeeAccount/info/getActiveUserAllInfo',
  getUserCompany: '/api/companystaff/employee/listByCurrent',
  getTasksDetail: 'api/contract/taskController/getByMobile',
  getMyTasks: '/api/contract/taskController/pageByEmployee',
  applyTasks: '/api/contract/taskController/addTaskApply',
  getAD: '/api/content/contentPoster/listToActiveUser',
  getNoLoginAD: '/api/content/contentPoster/listToNoLoginUser',

  getBankCardList: '/api/companystaff/employeeBankCard/findListByActiveUser',
  getAlipayList: '/api/companystaff/employeeBankCard/findAlipayListByActiveUser',
  bankCardCheck: '/api/companystaff/employeeBankCard/saveCheckUser',
  bankCardSave: '/api/companystaff/employeeBankCard/saveToUser',
  deleteBankCard: '/api/companystaff/employeeBankCard/delete/',
  validBankCard: '/api/companystaff/employeeBankCard/saveToUserToCode',
  alipaySave: '/api/companystaff/employeeBankCard/saveAlipayToUser',
  alipayEdit: '/api/companystaff/employeeBankCard/editAlipayToUser',

  resetPassword: '/api/companystaff/employeeAccount/resetPassword',

  contractListByActiveUser: '/api/contract/contractEmployee/listByActiveUser',//获得当前登录用户的协议列表
  employeeGetAllInfo: '/api/contract/contractEmployee/info/getAllInfo',//发起电签 获取当前登录用户除了密码外的所有信息外加银行卡信息，签署地址
  idImage: '/api/companystaff/employeeAccount/image',//保存身份证正反面照片，base64流
  idImage2: '/api/companystaff/employeeAccount/image2', //银行卡四要素 运营商三要素认证接口
  reSendAuthCode:'/api/companystaff/employeeAccount/reSendAuthCode',//认证发送手机验证码
  authCodeAudit:'/api/companystaff/employeeAccount/authCodeAudit',//认证


  updateResume: '/api/companystaff/employeeAccount/updateResume',

  getSalaryList: '/api/emolument/emolumentDetail/getList',
  getRecentlySalary: '/api/emolument/emolumentDetail/getUserRecently',
  getRecentlyDetail: '/api/emolument/emolumentDetail/getInfo/',
  getEcompany: '/api/companystaff/ecompany/getNamesByActiveUser',
  hotTaskList: '/api/contract/taskController/pageByHot',
  // 友链
  getFriendLinks: '/api/content/link/allList',
  checkId: '/api/companystaff/employeeAccount/check/',
  sendSmsRegister: '/api/admin/vcc/sendSms',
  register: 'api/companystaff/employeeAccount/register',
  setPassword: '/api/companystaff/employeeAccount/setPassword',
  newsList: '/api/message/message/userList',
  articleList: '/api/content/contentArticle/pcList',
  hasUnReadMessage: '/api/message/messageReceiver/hasUnReadMessage',
  setEmployeeSetSalaryVoucher: '/api/emolument/emolumentDetail/setEmployeeSetSalaryVoucher',

  unLoginTaskCenterList: '/api/contract/taskController/pageByAll',
  getMyTaskDetail: '/api/contract/taskController/getByEmployee',//我的任务明细
  getSubmitByEmployee: '/api/contract/taskController/getSubmitByEmployee',//我的任务明细交付数据
  addTaskSubmit: '/api/contract/taskController/addTaskSubmit',
  jobCategoryTree: '/api/admin/jobCategory/stateTreeList',
  // jobCategoryStateTree: '/api/admin/jobCategory/stateTreeList',
  bank: '/api/contract/contractEmployee/bank',//保存员工签署协议绑定银行卡
  logout: '/api/auth/jwt/cleanToken',
  checkPayType: '/api/companystaff/employeeBankCard/checkPayType', // 校验是否有支付宝

  getOcompanyBalance: '/api/emolument/virtualAccountRecord/getBalanceByAccount',//获取当前用户账号余额信息
  getAccount : '/api/companystaff/employeeBankCard/findAlipayListByActiveUser',
  checkCashAmount: '/api/emolument/virtualAccountRecord/checkCashAmount',//校验提取金额 以及余额
  getEmployeeAccount: '/api/companystaff/employeeBankCard/getEmployeeAccount',// 获取支付宝信息
  sendUserVerifySms:'/api/emolument/emolument/sendUserVerifySmsByPC',//余额提取发送验证码
  checkCode: "/api/emolument/virtualAccountRecord/checkCode",//余额提取校验验证码
  checkAndCash: '/api/emolument/virtualAccountRecord/checkAndCash',
  //getOcompanyBalance: '/api/emolument/virtualAccountRecord/getBalanceByAccount',
  getOcompanyNameByAccount: '/api/emolument/virtualAccountRecord/getOcompanyNameByAccount',
  getRecordList: '/api/emolument/virtualAccountRecord/getRecordList',

  getRelationIndividual: '/api/companystaff/ocompany/relationIndividual', //判断人力公司是否为个体户人力公司
  getRelationIndividualOcompany: '/api/companystaff/employeeAccount/relationIndividualOcompany', //判断人力公司是否为个体户人力公司
  getIndividualBusiness:  '/api/companystaff/individualBusiness/getIndividualBusinessByEmployeeAccountId', //签名个体工商户注册
  getById: '/api/companystaff/individualBusiness/getById',

  //判断当前人力公司是否支持 支付宝 付款方式
  checkSupportAliPay: '/api/companystaff/employeeBankCard/checkSupportAliPay',

  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',
  // :'',


}

let list = {}

Object.keys(apiList).forEach((key) => {
  /*
    data 请求参数
    methods：请求方式（axios提供的请求方式：如'get'||'post'||'patch'）
    subUrl: 拼接子url
  */
  list[key] = (data = {}, { method = 'get', subUrl = '' } = {}) => {
    let obj = {
      url: apiList[key] + subUrl,
      method
    }
    if (method == 'get') {
      obj.params = data
    } else {
      obj.data = data
    }
    return fetch(obj).catch(error => {
      if (error) {
        console.log("catch到错误：", error)
      }
    });
  }
})

export {
  apiList
}

export default list;



