<template>
  <div class="grejobHeader" :class="{simple:bodyScroll>74}">
    <div class="top">
      <div class="content">
        <div class="info">
          <router-link to="/login" v-if="JSON.stringify(user) === '{}'">游客，请登录！</router-link>
          <p v-else>
            欢迎
            <span class="text-ellipsis">{{ user.name }}{{ user.mobile }}</span
            >，
            <a href="javascript:void(0);" @click="handleLogout">退出</a>
          </p>
        </div>
        远程工作，为有效工作时间付费!
      </div>
    </div>
    <header>
      <router-link to="/" class="logobox">
        <img src="@/assets/logo.png" class="logo" />
        <img src="@/assets/slogan.png" class="slogan" />
      </router-link>
      <el-badge :value="unReadNum" v-if="JSON.stringify(user) !== '{}'">
        <el-button
          icon="el-icon-message-solid"
          circle
          class="notice"  
          @click="
            $router.push({
              path: '/userCenter/messageList',
              query: { tab: 'third' },
            })
          "
        ></el-button>
      </el-badge>
      <div class="nav_list">
        <router-link to="/" :class="{ active: $route.path == '/' }">
          工蚁首页
        </router-link>
        <router-link
          to="/tasks"
          :class="{ active: $route.path.startsWith('/tasks') }"
          >任务大厅</router-link>
          <router-link
          to="/myTasks"
          :class="{ active: $route.path.startsWith('/myTasks') }"
          >我的任务</router-link>
        <router-link to="/salary" :class="{ active: $route.path.startsWith('/salary') }">
          我的收入
        </router-link>
        <router-link
          to="/userCenter/index"
          :class="{ active: $route.path.startsWith('/userCenter/') }"
        >
          个人中心
        </router-link>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/list";
export default {
  data() {
    return {
      unReadNum: 0,
      bodyScroll:0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    $route: function (to, from) {
      this.getUnReadMessage();
    },
    user: function () {
      this.getUnReadMessage();
    },
  },
  mounted() {
    this.getUnReadMessage();
    window.addEventListener('scroll', ()=>{
      this.bodyScroll = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop; 
    }) 
  },
  methods: {
    getUnReadMessage() {
      if (JSON.stringify(this.user) !== "{}") {
        api.hasUnReadMessage().then((res) => {
          if (res.data) {
            this.unReadNum = res.data;
          }
        });
      }
    },
    handleLogout() {
      this.$confirm("此操作将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("FedLogOut").then(() => {
            this.$router.push({ path: "/login" });
            setTimeout(function () {
              location.reload();
            }, 500);
          });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "已取消退出操作",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.grejobHeader {
  display: block;
  height: 100px;
  background: #1d1e25;
  overflow: hidden;
  margin-bottom: 0;
  //transition: all 300ms linear; 

  &.simple{
    height: 26px; 
    margin-bottom: 100px;
    .top{
      position: fixed;
    }
  }

  .top {
    position: relative;
    left: 0;
    width: 100%;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    color: #b4b4b4;
    background: #23252d;
    z-index: 999;

    .content {
      width: 1210px;
      padding: 0 10px;
      margin: 0 auto;
    }

    .info {
      float: right;
      span,
      a {
        display: inline-block;
        vertical-align: middle;
        margin-top: -2px;
        color: #b4b4b4;
        max-width: 16em;
      }
      a:hover {
        color: #7385f4;
      }
    }
  }
  header {
    display: block;
    position: relative;
    width: 1210px;
    margin: 0 auto;
    padding: 0 10px;
    
    .logobox {
      float: left;
      padding-top: 10px;
      img {
        display: inline-block;
        vertical-align: middle;

        &.slogan {
          margin: 10px 0 0 12px;
        }
      }
    }

    .el-badge {
      position: absolute;
      right: 30px;
      top: 12px;
      border: none;

      /deep/ .el-badge__content {
        border: none;
        top: 8px;
        right: 12px;
      }
    }

    .notice {
      width: 52px;
      height: 52px;
      background: #464751;
      border: none;
      text-align: center;

      /deep/.el-icon-message-solid {
        color: #b6b6b6;
        font-size: 28px;
      }
    }

    .nav_list {
      padding: 20px;
      text-align: center;
      /deep/ a {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        font-size: 18px;
        color: #979799;
        line-height: 34px;
        margin: 0 20px;
        padding: 0 10px;

        &.router-link-exact-active,
        &.active {
          color: #7385f4;

          &:before {
            content: " ";
            position: absolute;
            left: 0;
            bottom: -20px;
            width: 100%;
            height: 8px;
            background: #7385f4;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
</style>
